<template>
    <v-row no-gutters>
        <v-col cols="12">
            <label class="f-label">{{$t(label)}}</label>
        </v-col>        
    </v-row>
</template>
<script>
export default {
    name: "Label",
    props: {
        label: {
            type: String,
            default: null
        }
    }           
};
</script>

